import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { LinksService } from '@app/core/links.service';
import { BannerType } from '@omgui/omgui-banner/omgui-banner.component';
import { OmguiButtonSize, OmguiButtonType } from '@omgui/omgui-button/omgui-button.component';

import { AuthService } from '../auth.service';
import { SendForgotPasswordEmailGraphQL } from './send-forgot-password-email-graphql.service';

export const REDEEM_MEMBERSHIP_FROM_EMAIL = 'redeemMembershipFromEmail';

@Component({
  selector: 'om-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPassword implements OnInit, AfterViewInit {
  @ViewChild('redeemMembershipFromEmailModal') redeemMembershipFromEmailModal: TemplateRef<any>;

  protected readonly OmguiButtonType = OmguiButtonType;
  protected readonly OmguiButtonSize = OmguiButtonSize;
  protected readonly genericErrorMessage = 'There was a problem. Please try again later.';
  protected readonly BannerType = BannerType;

  forgotPasswordForm: UntypedFormGroup;
  isInvalidFormSubmitted = false;
  processing = false;
  showErrorMsg = false;
  showMfaVerification = false;
  title = 'Forgot password';
  mailto = {
    href: 'mailto:techsupport@onemedical.com',
    displayText: 'techsupport@onemedical.com',
  };

  ctaText = 'Reset password';
  preferredEmailText = 'Send the link to my preferred email address instead';

  redeemMembershipFromEmailModalContent = {
    title: 'After password reset, redeem membership from email',
    body:
      'Once you reset your password, you’ll need to revisit your confirmation or invite email to redeem your purchase.',
  };

  constructor(
    readonly links: LinksService,
    private sendForgotPasswordEmail: SendForgotPasswordEmailGraphQL,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    if (this.mfaVerification()) {
      this.showMfaVerification = true;
      this.title = 'Secure your account';
      this.mailto = {
        href:
          'mailto:mfasupport@onemedical.com?subject=MFA%20Password%20Reset%20Support%20Needed&body=In%20order%20for%20our%20team%20to%20best%20assist%20you%20with%20your%20account%20needs%2C%20please%20answer%20the%20prompts%20below%20and%20send%20this%20email.%0A%0AFull%20Name%20-%20%0ADate%20of%20Birth%20-%20%0AEmail%20address%20you%20used%20to%20log%20in%20to%20One%20Medical%20%28please%20note%20this%20will%20help%20with%20identifying%20the%20correct%20account%20if%20you%27re%20responding%20from%20a%20different%20email%20address%29%20-%20%0ADid%20you%20check%20the%20checkbox%20to%20indicate%20you%20wanted%20the%20password%20reset%20link%20to%20be%20sent%20to%20your%20preferred%20email%20address%3F%20%0A%0APlease%20feel%20free%20to%20share%20any%20additional%20details%20about%20the%20problem%20you%27re%20experiencing%20-%20',
        displayText: 'mfasupport@onemedical.com',
      };
      this.ctaText = 'Send email';
      this.preferredEmailText =
        "I can't receive emails at that address. Send the link to my preferred email address instead.";
    }

    this.forgotPasswordForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.email, Validators.required]),
      preferredEmail: new UntypedFormControl(''),
    });
  }

  ngAfterViewInit() {
    const queryParams = new URLSearchParams(this.route.snapshot.queryParams);

    if (queryParams.has(REDEEM_MEMBERSHIP_FROM_EMAIL)) {
      this.modalService.open(this.redeemMembershipFromEmailModal, {
        size: 'md',
        centered: true,
        modalDialogClass: 'modal-redesign',
      });

      if (this.mfaVerification()) {
        this.redeemMembershipFromEmailModalContent = {
          title: 'After securing your account, redeem membership from email',
          body:
            'Once you secure your account, you’ll need to revisit your confirmation or invite email to redeem the purchase.',
        };
      }
    }
  }

  get emailControl() {
    return this.forgotPasswordForm.get('email') as UntypedFormControl;
  }

  get email() {
    return this.forgotPasswordForm.controls['email'].value;
  }

  get preferredEmail() {
    return Boolean(this.forgotPasswordForm.controls['preferredEmail'].value);
  }

  private mfaVerification() {
    return this.router.url.includes('mfa_verification');
  }

  private resetFormAndState() {
    this.forgotPasswordForm.reset();
    this.resetFormState();
  }

  private resetFormState() {
    this.isInvalidFormSubmitted = false;
    this.processing = false;
  }

  onSubmit() {
    this.showErrorMsg = false;

    if (this.forgotPasswordForm.valid) {
      this.processing = true;
      this.sendForgotPasswordEmail
        .mutate({
          email: this.email,
          usePreferredEmail: this.preferredEmail,
          mfaVerification: this.showMfaVerification,
        })
        .subscribe({
          next: ({ data: { sendForgotPasswordEmail } }) => {
            if (sendForgotPasswordEmail.success) {
              const params = new URLSearchParams({ mfaVerification: this.mfaVerification().toString() });
              this.authService.logout(`/forgot_password/email_sent?${params.toString()}`);
            } else {
              this.showErrorMsg = true;
              this.resetFormState();
            }
          },
          error: () => {
            this.showErrorMsg = true;
            this.resetFormState();
          },
        });
    } else {
      this.isInvalidFormSubmitted = true;
    }
  }
}
