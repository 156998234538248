import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

import { ContentBlocks, ContentBlock } from '@app/home-redesign/__generated__/content-block-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class ContentBlocksGraphQL extends Query<ContentBlocks> {
  document = gql`
    query contentBlocks {
      contentBlocks {
        title
        copy
        metadata {
          primaryAction
          primaryActionLabel
        }
      }
    }
  `;
}

@Injectable({
  providedIn: 'root',
})
export class ContentBlockGraphQL extends Query<ContentBlock> {
  document = gql`
    query ContentBlock($section: String!, $page: String, $title: String!, $serviceAreaId: ID) {
      contentBlock(section: $section, page: $page, title: $title, serviceAreaId: $serviceAreaId) {
        title
        copy
        metadata {
          primaryAction
          primaryActionLabel
        }
      }
    }
  `;
}
