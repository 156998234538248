import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LetModule } from '@rx-angular/template/let';
import { RecaptchaModule } from 'ng-recaptcha';
import { NgrxFormsModule } from 'ngrx-forms';

import { ProviderService } from '@app/account/my-provider/provider-graphql.service';
import { AppointmentSearchStateService } from '@app/appointment/appointment-search-service/appointment-search-state.service';
import { AppointmentSearchService } from '@app/appointment/appointment-search.service';
import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { MembershipGraphQL } from '@app/core/membership-graphql.service';
import { PipesModule } from '@app/pipes/pipes.module';
import { AppointmentConfirmationModalComponent } from '@app/shared/appointment-confirmation-modal/appointment-confirmation-modal.component';
import { CouponService } from '@app/shared/coupon.service';
import { Day1BannerComponent } from '@app/shared/day1-banner/day1-banner.component';
import { DropdownFormComponent } from '@app/shared/dropdown-form/dropdown-form.component';
import { EmailVerificationComponent } from '@app/shared/email-verification/email-verification.component';
import { ExcerptPipe } from '@app/shared/excerpt.pipe';
import { NextRemoteVisitComponent } from '@app/shared/next-remote-visit/next-remote-visit.component';
import { OfficeService } from '@app/shared/office.service';
import { ServiceAreaService } from '@app/shared/service-area.service';
import { StripePaymentRequestButtonComponent } from '@app/shared/stripe-credit-card/stripe-payment-request-button.component';
import { SvgRequestRecordsComponent } from '@app/shared/svg/svg-request-records/svg-request-records.component';
import { VirtualPropsModalComponent } from '@app/shared/virtual-props-modal/virtual-props-modal.component';
import { TrackLinkModule } from '@app/utils/track-link.directive';
import { WindowService } from '@app/utils/window.service';
import { OmguiModule } from '@omgui/omgui.module';

import {
  AccordionComponent,
  AddressAutocompleteComponent,
  AddressBasicInputComponent,
  AddressDisplayComponent,
  AddressOptionInputComponent,
  AddToCalendarComponent,
  AdultDirectSignupFormComponent,
  AmfValuePropsComponent,
  AppointmentMoreOptionsComponent,
  AppStoreRedirectService,
  BackButtonComponent,
  CardComponent,
  CardDirective,
  CardListModule,
  CardSliderComponent,
  CardTableModule,
  CarouselModalComponent,
  CheckboxComponent,
  CheckboxInputComponent,
  CheckmarkListComponent,
  ClearableAddressAutocompleteComponent,
  ConfirmationModalComponent,
  ContentBlockService,
  CovidVaccinationNextSteps,
  CreateHealthInformationReleaseAuthorizationGraphqlService,
  CreditCardComponent,
  DateInputComponent,
  DeleteConfirmationInlineFormComponent,
  DescriptionListCardModule,
  DirectSignupBannerComponent,
  DirectSignupComponent,
  DownloadAppCardComponent,
  DownloadAppLinkInputComponent,
  DownloadAppModalComponent,
  DownloadAppModalContent,
  EmailInputComponent,
  EmailVerificationRegistrationComponent,
  ExpandingTextBox,
  ExpiredMembershipBannerComponent,
  FileSizePipe,
  FlashComponent,
  FocusTargetDirective,
  FooterComponent,
  HeadlessModalTemplateComponent,
  HealthInformationReleaseFormComponent,
  HealthInformationReleaseService,
  IconItemComponent,
  InfoCardBodyDirective,
  InfoCardComponent,
  InfoCardHeaderDirective,
  InlineFormComponent,
  InternalRedirectPromptModalComponent,
  InviteFriendsModalComponent,
  LabelWrapper,
  LegalAgreementComponent,
  LegalDocComponent,
  LoggedInWrapperComponent,
  LoginPromptModalComponent,
  LogoSpinner,
  LogoutPromptModalComponent,
  ModalTemplateComponent,
  NameInputComponent,
  OfficeDetailsComponent,
  OfficePermalinkGraphqlService,
  OmguiCheckboxComponent,
  OmguiClearableTextInputComponent,
  OmguiSummaryRowModule,
  OmRecaptchaComponent,
  PaginationComponent,
  PasswordInputComponent,
  PhoneNumberInputComponent,
  PhoneNumberPipe,
  PhoneNumberToLinkPipe,
  ProfileBubbleWithDetailsComponent,
  ProgramPromotionCardComponent,
  PusherService,
  RadioGroupComponent,
  RadioInputNgrxComponent,
  RedirectPromptModalComponent,
  ReferralBannerComponent,
  RegistrationRestartComponent,
  RoutingGroupComponent,
  RoutingGroupOptionComponent,
  SeparatorComponent,
  ServiceAreaSelectComponent,
  SexGenderInputComponent,
  SidebarComponent,
  SmallCardComponent,
  SmallCardSvgDirective,
  StartCasePipe,
  StateService,
  StripeCreditCardComponent,
  StripeService,
  StyledMapComponent,
  SubmitButton,
  TaskService,
  ToastComponent,
  ToggleComponent,
  TosComponent,
  TruncatedMarkdown,
  UrlToLinkPipe,
  MfaRegStepComponent,
} from '.';
import { OmguiDateInputComponent } from '../omgui/omgui-date-input/omgui-date-input.component';
import { OmguiInputComponent } from '../omgui/omgui-input/omgui-input.component';
import { BookConfirmationModalActionComponent } from './appointment-confirmation-modal/appointment-confirmation-modal-action/book-confirmation-modal-action/book-confirmation-modal-action.component';
import { RescheduleConfirmationModalActionComponent } from './appointment-confirmation-modal/appointment-confirmation-modal-action/reschedule-confirmation-modal-action/reschedule-confirmation-modal-action.component';
import { SwitchToRemoteConfirmationModalActionComponent } from './appointment-confirmation-modal/appointment-confirmation-modal-action/switch-to-remote-confirmation-modal-action/switch-to-remote-confirmation-modal-action.component';
import { DateFormatPipe } from './date-format.pipe';
import { DateInputPickerComponent } from './date-input-picker/date-input-picker.component';
import { DetectResizeDirective } from './detect-resize/detect-resize.directive';
import { DetectResizeService } from './detect-resize/detect-resize.service';
import { EnterViewportObserverDirective } from './enter-viewport-observer.directive';
import { FaqComponent } from './faq/faq.component';
import { FooterAnalyticsService } from './footer/footer-analytics.service';
import { LabelWithTooltipComponent } from './label-with-tooltip/label-with-tooltip.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { LottieComponent } from './lottie/lottie.component';
import { NativeAppService } from './native-app.service';
import { NavbarContainerComponent } from './navbar/navbar-container.component';
import { NavbarDesktopComponent } from './navbar/navbar-desktop/navbar-desktop.component';
import { NavbarDropdownComponent } from './navbar/navbar-desktop/navbar-dropdown/navbar-dropdown.component';
import { NavbarMobileComponent } from './navbar/navbar-mobile/navbar-mobile.component';
import { OmguiBadgeComponent } from './omgui/omgui-badge/omgui-badge.component';
import { PaymentModalAnalyticsService } from './payment-modal/payment-modal-analytics.service';
import { PaymentModalComponent } from './payment-modal/payment-modal.component';
import { SafeHtmlPipe } from './safe-style-html.pipe';
import { SelfPayConfirmationComponent } from './self-pay-confirmation/self-pay-confirmation.component';
import {
  ArrowBackComponent,
  ArrowLeftComponent,
  ArrowNextComponent,
  ArrowRightComponent,
  CalendarComponent,
  CircleWithCheckmarkComponent,
  CloseComponent,
  ExclamationInBubbleComponent,
  LocationOnMapComponent,
  LogoComponent,
  LogoSmComponent,
  PencilComponent,
  PhoneComponent,
  ProviderComponent,
  ShowPasswordComponent,
  SpecialtyComponent,
  Svg,
  SvgAddFamilyMemberComponent,
  SvgAfterpayLogoComponent,
  SvgAllergyComponent,
  SvgApplepayLogoComponent,
  SvgBikeComponent,
  SvgCaretDownComponent,
  SvgCaretRightModule,
  SvgCaretUpComponent,
  SvgCheckmarkComponent,
  SvgCircleWithExclamationPointComponent,
  SvgCopyIcon,
  SvgDownloadAppComponent,
  SvgExclamationComponent,
  SvgEyeClosedComponent,
  SvgEyeOpenComponent,
  SvgFacebookLinkComponent,
  SvgFamilyComponent,
  SvgGermComponent,
  SvgGiftBoxIcon,
  SvgHamburgerComponent,
  SvgHealthInformationExchangeComponent,
  SvgInfoComponent,
  SvgInstagramLinkComponent,
  SvgLabResultComponent,
  SvgLinkedInLinkComponent,
  SvgMedicationComponent,
  SvgMembershipAndBillingComponent,
  SvgMessageComponent,
  SvgMessageOutlineComponent,
  SvgMobileWarning,
  SvgNotificationSettingsComponent,
  SvgPhoneInBlueCircle,
  SvgPhoneInGreenCircle,
  SvgProfileComponent,
  SvgProgressPieComponent,
  SvgSecurityComponent,
  SvgSecurityFilledCrossComponent,
  SvgStethoscopeComponent,
  SvgTriangleWarningComponent,
  SvgTwitterLinkComponent,
  SvgVaccineComponent,
  SvgVirtualVisitComponent,
  SvgVitalsComponent,
  SvgWatchComponent,
  SvgYoutubeLinkComponent,
  ToggleCaretComponent,
  UserOnCompComponent,
  ValidationXComponent,
  XWithCircleComponent,
} from './svg';
import { SvgActionItemComponent } from './svg/svg-action-item/svg-action-item.component';
import { SvgOfficeLogoComponent } from './svg/svg-office-logo/svg-office-logo.component';
import { SvgParentAndChildIconComponent } from './svg/svg-parent-and-child-icon/svg-parent-and-child-icon-component';
import { SvgVaccinesComponent } from './svg/svg-vaccines/svg-vaccines.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { ViewMoreComponent } from './view-more/view-more.component';

@NgModule({
  declarations: [
    AccordionComponent,
    AddressAutocompleteComponent,
    AddressBasicInputComponent,
    AddressDisplayComponent,
    AddressDisplayComponent,
    AddressOptionInputComponent,
    AddToCalendarComponent,
    AdultDirectSignupFormComponent,
    AmfValuePropsComponent,
    AppointmentConfirmationModalComponent,
    AppointmentMoreOptionsComponent,
    ArrowBackComponent,
    ArrowNextComponent,
    ArrowRightComponent,
    BookConfirmationModalActionComponent,
    CalendarComponent,
    CardComponent,
    CardDirective,
    CardSliderComponent,
    CarouselModalComponent,
    CheckboxComponent,
    CheckboxInputComponent,
    CheckmarkListComponent,
    CircleWithCheckmarkComponent,
    ClearableAddressAutocompleteComponent,
    CloseComponent,
    ConfirmationModalComponent,
    CovidVaccinationNextSteps,
    CreditCardComponent,
    DateFormatPipe,
    DateInputComponent,
    DateInputPickerComponent,
    Day1BannerComponent,
    DeleteConfirmationInlineFormComponent,
    DetectResizeDirective,
    DirectSignupBannerComponent,
    DirectSignupBannerComponent,
    DirectSignupComponent,
    DownloadAppCardComponent,
    DownloadAppLinkInputComponent,
    DownloadAppModalComponent,
    DownloadAppModalContent,
    DropdownFormComponent,
    EmailVerificationComponent,
    EmailVerificationRegistrationComponent,
    EnterViewportObserverDirective,
    ExcerptPipe,
    ExclamationInBubbleComponent,
    ExpandingTextBox,
    ExpiredMembershipBannerComponent,
    FaqComponent,
    FileSizePipe,
    FlashComponent,
    FocusTargetDirective,
    FooterComponent,
    HeadlessModalTemplateComponent,
    HealthInformationReleaseFormComponent,
    IconItemComponent,
    InfoCardBodyDirective,
    InfoCardComponent,
    InfoCardHeaderDirective,
    InlineFormComponent,
    InternalRedirectPromptModalComponent,
    InviteFriendsModalComponent,
    LabelWithTooltipComponent,
    LabelWrapper,
    LegalAgreementComponent,
    LegalDocComponent,
    LoadingSpinnerComponent,
    LocationOnMapComponent,
    LoggedInWrapperComponent,
    LoginPromptModalComponent,
    LogoSmComponent,
    LogoSpinner,
    LogoutPromptModalComponent,
    LottieComponent,
    MfaRegStepComponent,
    ModalTemplateComponent,
    NameInputComponent,
    NavbarContainerComponent,
    NavbarDesktopComponent,
    NavbarDropdownComponent,
    NavbarMobileComponent,
    NextRemoteVisitComponent,
    OfficeDetailsComponent,
    OmguiBadgeComponent,
    OmguiCheckboxComponent,
    OmguiClearableTextInputComponent,
    OmguiDateInputComponent,
    OmguiInputComponent,
    OmRecaptchaComponent,
    PaginationComponent,
    PasswordInputComponent,
    PasswordInputComponent,
    PaymentModalComponent,
    PencilComponent,
    PhoneComponent,
    PhoneNumberInputComponent,
    PhoneNumberPipe,
    PhoneNumberToLinkPipe,
    ProfileBubbleWithDetailsComponent,
    ProgramPromotionCardComponent,
    ProviderComponent,
    RadioGroupComponent,
    RadioInputNgrxComponent,
    RedirectPromptModalComponent,
    ReferralBannerComponent,
    RegistrationRestartComponent,
    RescheduleConfirmationModalActionComponent,
    RoutingGroupComponent,
    RoutingGroupOptionComponent,
    SafeHtmlPipe,
    SelfPayConfirmationComponent,
    SeparatorComponent,
    ServiceAreaSelectComponent,
    SexGenderInputComponent,
    ShowPasswordComponent,
    ShowPasswordComponent,
    SidebarComponent,
    SmallCardComponent,
    SmallCardSvgDirective,
    SpecialtyComponent,
    StartCasePipe,
    StripeCreditCardComponent,
    StripePaymentRequestButtonComponent,
    StyledMapComponent,
    SubmitButton,
    SvgActionItemComponent,
    SvgAddFamilyMemberComponent,
    SvgAfterpayLogoComponent,
    SvgAllergyComponent,
    SvgApplepayLogoComponent,
    SvgBikeComponent,
    SvgCaretDownComponent,
    SvgCaretUpComponent,
    SvgCheckmarkComponent,
    SvgCircleWithExclamationPointComponent,
    SvgCopyIcon,
    SvgDownloadAppComponent,
    SvgExclamationComponent,
    SvgEyeClosedComponent,
    SvgEyeOpenComponent,
    SvgFacebookLinkComponent,
    SvgFamilyComponent,
    SvgGermComponent,
    SvgGiftBoxIcon,
    SvgHamburgerComponent,
    SvgHealthInformationExchangeComponent,
    SvgInstagramLinkComponent,
    SvgLabResultComponent,
    SvgLinkedInLinkComponent,
    SvgMedicationComponent,
    SvgMembershipAndBillingComponent,
    SvgMessageComponent,
    SvgMessageOutlineComponent,
    SvgMobileWarning,
    SvgNotificationSettingsComponent,
    SvgOfficeLogoComponent,
    SvgParentAndChildIconComponent,
    SvgPhoneInBlueCircle,
    SvgPhoneInGreenCircle,
    SvgProfileComponent,
    SvgProgressPieComponent,
    SvgRequestRecordsComponent,
    SvgSecurityComponent,
    SvgSecurityFilledCrossComponent,
    SvgStethoscopeComponent,
    SvgTriangleWarningComponent,
    SvgTwitterLinkComponent,
    SvgVaccineComponent,
    SvgVaccinesComponent,
    SvgVirtualVisitComponent,
    SvgVitalsComponent,
    SvgWatchComponent,
    SvgYoutubeLinkComponent,
    SwitchToRemoteConfirmationModalActionComponent,
    ToggleCaretComponent,
    ToggleComponent,
    TooltipComponent,
    TosComponent,
    TruncatedMarkdown,
    UrlToLinkPipe,
    UserOnCompComponent,
    ValidationXComponent,
    ViewMoreComponent,
    VirtualPropsModalComponent,
    XWithCircleComponent,
  ],
  imports: [
    ArrowLeftComponent,
    BackButtonComponent,
    CardListModule,
    CardTableModule,
    CommonModule,
    DescriptionListCardModule,
    EmailInputComponent,
    FormsModule,
    GoogleMapsModule,
    LetModule,
    NgbModule,
    NgrxFormsModule,
    OmguiModule,
    OmguiSummaryRowModule,
    PipesModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RouterModule,
    Svg,
    SvgCaretRightModule,
    SvgInfoComponent,
    ToastComponent,
    TrackLinkModule,
    LogoComponent,
  ],
  providers: [
    AppointmentSearchService,
    AppointmentSearchStateService,
    AppStoreRedirectService,
    ContentBlockService,
    CouponService,
    CreateHealthInformationReleaseAuthorizationGraphqlService,
    DetectResizeService,
    FeatureFlagSelectors,
    FooterAnalyticsService,
    HealthInformationReleaseService,
    MembershipGraphQL,
    NativeAppService,
    OfficePermalinkGraphqlService,
    OfficeService,
    PaymentModalAnalyticsService,
    ProviderService,
    PusherService,
    ServiceAreaService,
    StateService,
    StripeService,
    TaskService,
    WindowService,
  ],
  exports: [
    AccordionComponent,
    AddressAutocompleteComponent,
    AddressBasicInputComponent,
    AddressDisplayComponent,
    AddressOptionInputComponent,
    AddToCalendarComponent,
    AdultDirectSignupFormComponent,
    AmfValuePropsComponent,
    AppointmentConfirmationModalComponent,
    AppointmentMoreOptionsComponent,
    ArrowBackComponent,
    ArrowLeftComponent,
    ArrowNextComponent,
    ArrowRightComponent,
    BackButtonComponent,
    BookConfirmationModalActionComponent,
    CalendarComponent,
    CardComponent,
    CardDirective,
    CardListModule,
    CardSliderComponent,
    CardTableModule,
    CarouselModalComponent,
    CheckboxComponent,
    CheckboxInputComponent,
    CheckmarkListComponent,
    CircleWithCheckmarkComponent,
    ClearableAddressAutocompleteComponent,
    CloseComponent,
    ConfirmationModalComponent,
    CovidVaccinationNextSteps,
    CreditCardComponent,
    DateFormatPipe,
    DateInputComponent,
    DateInputPickerComponent,
    Day1BannerComponent,
    DeleteConfirmationInlineFormComponent,
    DescriptionListCardModule,
    DetectResizeDirective,
    DirectSignupBannerComponent,
    DirectSignupComponent,
    DownloadAppCardComponent,
    DownloadAppLinkInputComponent,
    DownloadAppModalComponent,
    DropdownFormComponent,
    EmailInputComponent,
    EmailVerificationComponent,
    EmailVerificationRegistrationComponent,
    EnterViewportObserverDirective,
    ExcerptPipe,
    ExclamationInBubbleComponent,
    ExpandingTextBox,
    ExpiredMembershipBannerComponent,
    FaqComponent,
    FileSizePipe,
    FlashComponent,
    FocusTargetDirective,
    HeadlessModalTemplateComponent,
    HealthInformationReleaseFormComponent,
    IconItemComponent,
    InfoCardBodyDirective,
    InfoCardComponent,
    InfoCardHeaderDirective,
    InlineFormComponent,
    InternalRedirectPromptModalComponent,
    InviteFriendsModalComponent,
    LabelWithTooltipComponent,
    LabelWrapper,
    LegalAgreementComponent,
    LegalDocComponent,
    LoadingSpinnerComponent,
    LocationOnMapComponent,
    LoggedInWrapperComponent,
    LoginPromptModalComponent,
    LogoComponent,
    LogoSmComponent,
    LogoSpinner,
    LogoutPromptModalComponent,
    LottieComponent,
    MfaRegStepComponent,
    ModalTemplateComponent,
    NameInputComponent,
    NavbarContainerComponent,
    NavbarDesktopComponent,
    NavbarDropdownComponent,
    NavbarMobileComponent,
    NextRemoteVisitComponent,
    OfficeDetailsComponent,
    OmguiBadgeComponent,
    OmguiCheckboxComponent,
    OmguiClearableTextInputComponent,
    OmguiDateInputComponent,
    OmguiInputComponent,
    OmguiSummaryRowModule,
    OmRecaptchaComponent,
    PaginationComponent,
    PasswordInputComponent,
    PencilComponent,
    PhoneComponent,
    PhoneNumberInputComponent,
    PhoneNumberPipe,
    PhoneNumberToLinkPipe,
    ProfileBubbleWithDetailsComponent,
    ProgramPromotionCardComponent,
    ProviderComponent,
    RadioGroupComponent,
    RadioInputNgrxComponent,
    ReferralBannerComponent,
    ReferralBannerComponent,
    RegistrationRestartComponent,
    RescheduleConfirmationModalActionComponent,
    RoutingGroupComponent,
    RoutingGroupOptionComponent,
    SafeHtmlPipe,
    SelfPayConfirmationComponent,
    SeparatorComponent,
    ServiceAreaSelectComponent,
    SexGenderInputComponent,
    SidebarComponent,
    SmallCardComponent,
    SmallCardSvgDirective,
    SpecialtyComponent,
    StartCasePipe,
    StripeCreditCardComponent,
    StripePaymentRequestButtonComponent,
    StyledMapComponent,
    SubmitButton,
    Svg,
    SvgActionItemComponent,
    SvgAddFamilyMemberComponent,
    SvgAfterpayLogoComponent,
    SvgAllergyComponent,
    SvgApplepayLogoComponent,
    SvgBikeComponent,
    SvgCaretDownComponent,
    SvgCaretRightModule,
    SvgCaretUpComponent,
    SvgCheckmarkComponent,
    SvgCircleWithExclamationPointComponent,
    SvgCopyIcon,
    SvgDownloadAppComponent,
    SvgExclamationComponent,
    SvgEyeClosedComponent,
    SvgEyeOpenComponent,
    SvgFamilyComponent,
    SvgGermComponent,
    SvgGiftBoxIcon,
    SvgHamburgerComponent,
    SvgHealthInformationExchangeComponent,
    SvgInfoComponent,
    SvgLabResultComponent,
    SvgMedicationComponent,
    SvgMembershipAndBillingComponent,
    SvgMessageComponent,
    SvgMessageOutlineComponent,
    SvgMobileWarning,
    SvgNotificationSettingsComponent,
    SvgOfficeLogoComponent,
    SvgParentAndChildIconComponent,
    SvgPhoneInBlueCircle,
    SvgPhoneInGreenCircle,
    SvgProfileComponent,
    SvgProgressPieComponent,
    SvgStethoscopeComponent,
    SvgTriangleWarningComponent,
    SvgVaccineComponent,
    SvgVirtualVisitComponent,
    SvgVitalsComponent,
    SwitchToRemoteConfirmationModalActionComponent,
    ToastComponent,
    ToggleCaretComponent,
    ToggleComponent,
    TooltipComponent,
    TosComponent,
    TruncatedMarkdown,
    UrlToLinkPipe,
    UserOnCompComponent,
    ValidationXComponent,
    ViewMoreComponent,
    VirtualPropsModalComponent,
    XWithCircleComponent,
  ],
})
export class SharedModule {}
