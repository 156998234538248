<div class="forgot-password-container container-fluid h-100">
  <div class="row text-center mb-4">
    <div class="col-10 offset-1 col-md-6 offset-md-3">
      <a [href]="links.home">
        <om-logo class="mx-auto mt-5"></om-logo>
      </a>
      <div class="mt-4" *ngIf="showErrorMsg" data-cy="error-banner">
        <omgui-banner [type]="BannerType.ErrorLight">{{ genericErrorMessage }}</omgui-banner>
      </div>

      <h1 class="mt-5">{{ title }}</h1>
      <p class="body-small p-0" *ngIf="!showMfaVerification">Enter the email address associated with your account.</p>
      <p class="body-small p-0" *ngIf="showMfaVerification">
        Set up Multi-factor Authentication to add more security to your account. This helps us ensure that only you see
        your health information.
        <br /><br />
        First, enter the email you used to log in on the previous page. We’ll send an email with a link to reset your
        password.
        <br /><br />
        Next, set up Multi-factor Authentication with a phone number.
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-10 offset-1 col-md-6 offset-md-3">
      <div class="form row">
        <div class="col">
          <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
            <label for="email" class="form-label mb-0 body-small font-weight-bold">Email</label>
            <input
              [class.is-invalid]="emailControl.invalid && isInvalidFormSubmitted"
              id="email"
              type="email"
              name="email"
              class="form-control"
              required
              formControlName="email"
              placeholder="Email"
            />
            <div *ngIf="emailControl.invalid && isInvalidFormSubmitted" data-cy="email-errors">
              <small *ngIf="emailControl.errors?.required" class="text-danger">Email is required</small>
              <small *ngIf="emailControl.errors?.email" class="text-danger">Please provide a valid email address</small>
            </div>

            <omgui-checkbox
              class="d-block mt-3 mb-4"
              [label]="preferredEmailText"
              formControlName="preferredEmail"
            ></omgui-checkbox>
            <omgui-button [disabled]="processing" [type]="OmguiButtonType.submit" [size]="OmguiButtonSize.fillParent">
              <span [hidden]="processing">{{ ctaText }}</span> <span [hidden]="!processing">Processing...</span>
            </omgui-button>
          </form>

          <ng-container *ngIf="showMfaVerification">
            <p class="body-small mt-4 text-center text-emphasis-medium">
              If you are experiencing a looping issue in this flow, please clear your browser cache & cookie files and
              try again. If the issue persists, please e-mail
              <a href="mailto:mfasupport@onemedical.com" target="_blank">mfasupport@onemedical.com</a> or call
              1-888-ONE-MED1. Please also reach out if you don’t receive an e-mail within 30 minutes.
            </p>
          </ng-container>
          <ng-container *ngIf="!showMfaVerification">
            <p class="body-small mt-4 text-center text-emphasis-medium">
              If you don’t receive an email within 30 minutes, please email
              <a [href]="mailto.href" target="_blank">{{ mailto.displayText }}</a>
            </p>
          </ng-container>
        </div>
      </div>
      <div class="actions row my-5 text-center" *ngIf="!showMfaVerification">
        <div class="col">
          <p class="mb-3">
            Remember your password?
            <a [href]="links.login">Log in</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #redeemMembershipFromEmailModal let-dismiss="dismiss">
  <div class="p-4">
    <div class="modal-body text-center">
      <h2>{{ redeemMembershipFromEmailModalContent.title }}</h2>
      <p class="mb-4">{{ redeemMembershipFromEmailModalContent.body }}</p>
      <omgui-button (buttonClick)="dismiss()" [size]="OmguiButtonSize.fillParent"> Okay </omgui-button>
    </div>
  </div>
</ng-template>
