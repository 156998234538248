import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

import {
  AppointmentTypeInfoForAnalytics,
  AppointmentTypeInfoForAnalyticsVariables,
} from '@app/home-redesign/__generated__/appointment-type-info-for-analytics-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class AppointmentTypeInfoForAnalyticsGraphQLService extends Query<
  AppointmentTypeInfoForAnalytics,
  AppointmentTypeInfoForAnalyticsVariables
> {
  document = gql`
    query AppointmentTypeInfoForAnalytics($id: ID!) {
      appointment(id: $id) {
        id
        appointmentType {
          id
          displayName
        }
      }
    }
  `;
}
