import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, of as observableOf, switchMap, take } from 'rxjs';

import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';

import { LinksService } from '../core/links.service';

@Injectable({
  providedIn: 'root',
})
export class GetCareDeepLinkGuard implements CanActivate {
  constructor(private links: LinksService, private router: Router, private launchDarklyService: LaunchDarklyService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.launchDarklyService.featureFlag$<boolean>(FeatureFlags.PC_TMN_QR_CODE_MOBILE_FORWARDING, false).pipe(
      take(1),
      switchMap(flagActive =>
        flagActive
          ? observableOf<true | UrlTree>(
              route.queryParamMap.get('careOptionName') === 'TreatMeNow' ||
                this.router.parseUrl(this.links.appointmentsGetCare),
            )
          : observableOf(this.router.parseUrl(this.links.appointmentsGetCare)),
      ),
    );
  }
}
