import { TimelinePostsWithComments_patient_posts_nodes_s3Pointers } from '@app/message/__generated__/TimelinePostsWithComments';

import { LabResult_patient_labResult_s3Pointer } from '../health-record/lab-results/__generated__/LabResult';

export type S3Pointers =
  | LabResult_patient_labResult_s3Pointer
  | TimelinePostsWithComments_patient_posts_nodes_s3Pointers;

export class DocumentItem {
  id: number;
  title: string;
  key: string;
  bucket: string;
  contentType: string;
  contentLength: number;

  static fromApiV2(documentObject: Record<string, any>) {
    const document = new DocumentItem();
    document.id = documentObject.id;
    document.title = documentObject.title;
    document.key = documentObject.key;
    document.bucket = documentObject.bucket;
    document.contentType = documentObject.content_type;
    document.contentLength = documentObject.content_length;

    return document;
  }

  static fromGraphql(documentObject: S3Pointers) {
    const document = new DocumentItem();
    document.id = +documentObject.id;
    document.title = documentObject.title;
    document.key = documentObject.key;
    document.bucket = documentObject.bucket;
    document.contentType = documentObject.contentType;
    document.contentLength = documentObject.contentLength;

    return document;
  }
}
